
.em-upload-music {
  display: flex;
  width: 100%;

  .em-dashboard {
    position: relative;
  }
  .em-db-sidebar {
    padding: 19px 16px;
    background: #1e1f26;

    strong {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        height: 26px;
        width: 121px;
        left: 98px;
        top: 46px;
        color: #ffffff;
        letter-spacing: 0.2px;
      }
    }
    .em-db-footer {
      margin-top: 150px;
      a {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        color: #e7e7e7;
        text-decoration: none;
        margin-top: 34px;
      }
      i {
        margin-right: 14px;
        color: #e7e7e7;
      }
    }
    .em-db-footer-wraper {
      display: flex;
      align-items: center;
      gap: 16px;

      h6 {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        color: #ffff;
        margin: 0;
      }
      em {
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #e7e7e7;
        margin: 4px 0 0 0x;
      }
    }
  }
}
.em-upload-music-nav {
  width: 100%;
}
.em-upload-music-nav-wraper {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 28px 24px;
  background: rgba(0, 0, 0, 0.4);
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: baseline;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  li {
    list-style: none;
    display: flex;
    align-items: center;
    i.fa-check {
      background: #fe465a;
      color: #fff;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
    }
    &:hover,
    :active {
      span {
        background: #fe465a;
      }

      a {
        color: #fe465a;
      }
    }
    span {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.08);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-weight: 600;
      font-size: 16px;
      margin-right: 16px;
    }
    a {
      font-family: SFPro;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      color: #a1a1a1;
      text-decoration: none;
      display: inline-block;
      margin-right: 8px;
    }
    i {
      color: #a1a1a1;
    }
  }
  li.active {
    a {
      color: #fe465a;
    }
    span {
      background: #fe465a;
    }
  }
}
h5.title {
  font-family: SFPro;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  color: #ffffff;
  margin: 0;
}

.em-upload-track {
  padding: 32px 21px;
  @media screen and (max-width:480px) {
    padding: 0; 
  }
  h5 {
    font-family: SFPro;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    color: #ffffff;
    margin: 0;
    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }
}
.em-upload-track-info {
  margin: 24px 0 0 0;
  padding: 0;
  display: flex;
  gap: 19px;

  li {
    list-style: none;
  }
}
.em-upload-single-track {
  border-radius: 16px;
  padding: 32px;
  background: #1e1f26;
  text-align: center;
  border: 0.8px solid transparent;

  &:hover {
    border-radius: 16px;
    border: 0.8px solid #fe465a;
    i {
      color: #fe465a;
    }
  }

  h4 {
    font-family: SFPro;
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    color: #ffffff;
    margin: 24px 0 0 0;
    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }
  p {
    color: #a6a6a6;
    margin: 8px 0 0 0;
    font-family: SFPro;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
  i {
    font-size: 32px;
    color: #ffffff;
  }
}

.em-upload-single-track.active {
  border: 0.8px solid #fe465a;
}

.em-track-title-wrap {
  margin: 40px 0 0 0;
  padding: 0 24px;
  ul {
    padding: 0;
    margin: 0;
  }
  li + li {
    margin-top: 24px;
    list-style: none;
  }

  .em-form-group {
    .form-control {
      background: rgba(255, 255, 255, 0.08) !important;
      border: 0.8px solid #a1a1a1 !important;
      border-radius: 8px;
      padding: 16px;
      margin-top: 8px;
      color: #fafafa !important;
    }
    label {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #a1a1a1;
    }
  }
}

.em-music-store {
  // flex-wrap: wrap;
  padding: 0;
  margin: 30px 0 0 0;
  // display: flex;
  // gap: 22px;

  img {
    height: 76px;
    width: 76px;
    border-radius: 0px;
  }
  label {
    text-align: center;
  }

  .store-card {
    list-style: none;
    position: relative;
    background: rgba(255, 255, 255, 0.08);
    border: 0.8px solid #585858;
    border-radius: 8px;
    // width: 100%;
    // max-width: calc((100% / 5) - 18px);
    // max-width: 160px;
  }

  span {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    display: block;
    margin-top: 16px;
    color: white;
    white-space: nowrap;
  }
}
.em-music-store-info {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 29px 47px 19px 47px;
  cursor: pointer;

  label {
    cursor: pointer;
  }
  input[type="checkbox"] {
    position: absolute;
    right: 10px;
    top: 10px;
    border: 1.5px solid #f2f2f2;
    background: rgba(218, 223, 229, 0.1);
    opacity: 0.8;
    border-radius: 8px;
    border-radius: 2px;
    background: transparent;
    cursor: pointer;
    outline: 0;
    vertical-align: text-top;
    height: 24px;
    width: 24px;
    appearance: none;
    opacity: 0.5;
  }

  input[type="checkbox"]:hover {
    opacity: 1;
  }

  input[type="checkbox"]:checked {
    background: #fe465a;
    opacity: 1;
  }

  input[type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    right: 22px;
    top: 22px;
    width: 5px;
    height: 12px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
  }
}
.em-track-title {
  list-style: none;
  li {
    margin-top: 1rem;
    label {
      color: #a1a1a1;
    }
  }
}
.em-upload-track {
  .em-track-title {
    li {
      margin-top: 1rem;
      label {
        color: #a1a1a1;
      }
    }
    padding: 0;
    label {
      color: #a1a1a1;
    }
  }
}

.em-pricing {
  padding: 24px;

  .em-pricing-input {
    display: flex;
    align-items: center;
  }

  span {
    margin-left: auto;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    font-family: "Inter", sans-serif;
    color: #fafafa;
  }
  p {
    margin: 18px 0 0 0;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #a1a1a1;
  }
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  cursor: pointer;
  display: inline-block;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #fafafa;
  padding-left: 38px;
  transition: font-weight 0.15s linear;
}
[type="radio"]:checked + label{
  font-weight: 700 !important;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1.5px solid #f2f2f2;
  background: transparent;
}
[type="radio"]:checked + label:before{
  border-color: #fe465a !important;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 14px;
  height: 14px;
  background: #fe465a;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.em-pre-pricing {
  display: flex;
}
.em-upoading-text {
  margin: 10px 0 0 0;
  color: #fff;
}
.em-pre-pricing {
  margin: 24px 0 0 0;
  padding: 0 24px;
  gap: 19px;
  li {
    background: rgba(255, 255, 255, 0.08);
    border: 0.8px solid #a1a1a1;
    border-radius: 8px;
  }
}

.navbar-nav {
  flex-direction: column !important;
  justify-content: flex-start;
}

.navbar {
  align-items: flex-start;
}
.nav-link {
  padding: 0 !important;
  white-space: wrap;
  color: #e7e7e7 !important;
}

// ________________________________________________///my-Music......//////

.em-album {
  margin: 50px 0 0 0;
  padding: 0 24px;
  .em-top-release-music-info{
    li{
      
      border: none !important;
    }
  }
}
.em-album-info {
  display: flex;
  align-items: center;
  gap: 24px;
  h5 {
    font-family: "sfpro";
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    color: #ffffff;
  }
  .em-back-icon {
    i {
      border: 2px solid #6e6e6e;
      border-radius: 50%;
      display: flex;
      height: 48px;
      width: 48px;
      color: #f2f2f2;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }
  }
}
.em-album-img {
  display: flex;
  margin: 49px 0 0 0;

  figure{
    margin: 0;
  }
  li + li {
    margin-top: 32px;
  }
  li {
    list-style: none;

    span {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #a1a1a1;
    }
    h6 {
      font-family: "Inter", sans-serif;
      margin: 8px 0 0 0;
      color: #fafafa;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
 
}
.em-primary {
  margin-left: 148px;
  padding: 0;
}


  .em-top-release-music-profie-info{
    min-width: 15rem;
    @media screen and (max-width: 480px) {
        min-width: auto;
    }
    h6{
      margin-bottom: 8px;
      display: block;
    }

    }
