@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.em-bgcolor {
    background: linear-gradient(90deg, rgba(35, 21, 23, 1) 0%, rgba(18, 18, 19, 1) 49%, rgba(30, 25, 37, 1) 100%);
}

.container {
    width: 100%;
    max-width: 1190px;
    margin: 0 auto;
}

.payWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
}

.payWrap .pay-box {
    width: 46%;
}

.payWrap .inner-price {
    width: 46%;
}

p.pay-subHeading {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #FFFFFF !important;
}

.pay-box h2 {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 1.4;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    background: linear-gradient(90deg, #F7522B 0%, #941F7A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
}

.pay-box p {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    /* line-height: 140%; */
    line-height: 1.6;
    letter-spacing: 0.2px;
    color: #A1A1A1;
}

.payWrap .inner-price {
    width: 46%;
    display: flex;
    align-items: center;
    justify-content: right;
}

.price-box {
    padding: 20px;
    width: 100%;
    max-width: 400px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
}
@media screen and (max-width: 480px) {
    .price-box {
        max-width: unset;
    }
}

.price-box p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    margin: 10px 0px;
    font-family: 'SF Pro Text';
}

.price-box p span {
    padding-left: 20px;
}

.price-desc {
    font-size: 12px !important;
}

.price-box a {
    background: linear-gradient(102.9deg, #4857C0 3.03%, #FE465A 92.77%);
    border-radius: 80px;
    text-decoration: none;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    display: block;
    padding: 14px;
    font-family: 'DM Sans';
    margin-top: 30px;
}

/************SERVICES SECTIoN*****************/
.Services-section h2 {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 90px;
    text-align: center;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    background: linear-gradient(90deg, #F7522B 0%, #941F7A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.services-wrap {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    flex-wrap: wrap;
}

.service-box {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    width: 30%;
    margin-bottom: 20px;
    padding: 16px;
    transform: scale(1);
    transition: transform 0.25s linear;
}

.service-box:hover{
    transform: scale(1.05);
}

.service-box .ser-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FAFAFA;
    margin: 10px 0px;
}

.service-box .ser-price {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #FAFAFA;
    margin: 10px 0px;
}

.ser-list {
    padding-left: 0px;
    margin: 10px 0px;
}

.ser-list li {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FAFAFA;
    background-image: url(images/frontend/tick-icon.png);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 5px 2px;
    padding-left: 25px;
}

/********media queries*************/
@media(max-width:1049px) {
    .service-box {
        padding: 10px;
    }
    .em-bgcolor{
        padding: 0 15px;
    }
}

@media(max-width:800px) {
    .em-bgcolor{
        padding: 0 15px;
    }

    .price-box{
        margin: 40px auto;
    }
    .payWrap {
        flex-wrap: wrap;
    }

    .payWrap .pay-box {
        width: 100%;
    }

    .payWrap .inner-price {
        width: 100%;
    }

    .service-box {
        width: 44%;
        padding: 15px;
    }

    .Services-section h2 {
        font-size: 30px;
        line-height: 30px;
    }
}

@media(max-width:480px) {
    .pay-box p {
        font-size: 12px !important;
    }

    .pay-box p {
        font-size: 14px !important;
    }

    .pay-box .pay-subHeading {
        font-size: 14px;
    }

    .pay-box h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .Services-section h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .service-box {
        width: 100%;
        margin-bottom: 12px;
    }

    .service-box .ser-title {
        font-size: 14px;
    }

    .service-box .ser-price {
        font-size: 16px;
    }

    .ser-list li {
        font-size: 12px;
    }

    .Services-section{
        padding-top: 30px;
    }

    .Services-section>h2{
        padding: 15px 0;
    }
}