@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
}

.ambasor-section h2 {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    /* line-height: 90px; */
    text-align: center;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    background: linear-gradient(90deg, #F7522B 0%, #941F7A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.ambasor-section h2 span {
    -webkit-text-fill-color: #fff;
}

.em-ambassords {
    margin-bottom: 2rem;
}

.amb-desc {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.2px;
    color: #A1A1A1;
}

.amb-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.amb-box {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    width: 24%;
    margin-bottom: 12px;
    transform: scale(1);
    transition: transform 0.25s linear;
}

.amb-box:hover {
    transform: scale(1.05);
}

.amb-box .amb-inner {
    padding: 15px;
}

.amb-inner p {
    margin: 10px 0px !important;
}

.amb-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FAFAFA;
}

.amb-subTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FAFAFA;
}

.amb-list {
    padding-left: 0px;
}

.amb-list li {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FAFAFA;
    background-image: url("../../../assets/images/frontend/tick-icon.png");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 5px 2px;
    padding-left: 25px;
}

@media(max-width:920px) {
    .amb-desc br {
        display: none;
    }

    .amb-box {
        width: 32%;
    }
}

@media(max-width:800px) {
    .amb-box {
        width: 49%;
    }
    .ambasor-section h2{
        line-height: unset;
        padding: 15px 0;
        font-size: 32px;
    }
    .amb-desc{
        font-size: 16px;
        line-height: unset;
        margin-bottom: 20px;
    }
}

@media(max-width:480px) {
    .ambasor-section h2 {
        font-size: 24px;
        line-height: unset;
    }

    .amb-title{
        font-size: 18px;
        line-height: unset;
    }

    .amb-desc {
        font-size: 14px;
    }

    .amb-box {
        width: 100%;
    }
    .ambasor-section>h2{
        padding: 20px 0;
    }
    .amb-title{
        font-size: 22px;
    }
    .amb-emergence{
        font-size: 16px !important;
    }
}

.amb-emergence {
	background: linear-gradient(90deg, rgba(108, 84, 173, 1) 0%, rgba(240, 71, 98, 1) 100%);
	color: #fff;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 17px;
	text-decoration: none;
	padding: 16px 18px;
	border-radius: 35px;
	display: inline-block;
	/* text-transform: uppercase; */
}
.amb-hori-center{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}