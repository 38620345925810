
.em-upload-music {
  display: flex;
  width: 100%;

  .em-db-sidebar {
    padding: 19px 16px;
    background: #1e1f26;

    strong {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        height: 26px;
        width: 121px;
        left: 98px;
        top: 46px;
        color: #ffffff;
        letter-spacing: 0.2px;
      }
    }
    .em-db-footer {
      margin-top: 150px;
      a {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        color: #e7e7e7;
        text-decoration: none;
        margin-top: 34px;
      }
      i {
        margin-right: 14px;
        color: #e7e7e7;
      }
    }
    .em-db-footer-wraper {
      display: flex;
      align-items: center;
      gap: 16px;

      h6 {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        color: #ffff;
        margin: 0;
      }
      em {
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #e7e7e7;
        margin: 4px 0 0 0x;
      }
    }
  }
}
.em-upload-music-nav {
  width: 100%;
}
.em-upload-music-nav-wraper {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 28px 24px;
  background: #1e1f26;
  li {
    list-style: none;
    display: flex;
    align-items: center;
    &:hover,
    :active {
      span {
        background: #fe465a;
      }
      a {
        color: #fe465a;
      }
      i {
        color: #fe465a;
      }
    }
    span {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.08);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-weight: 600;
      font-size: 16px;
      margin-right: 16px;
    }
    a {
      font-family: SFPro;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      color: #a1a1a1;
      text-decoration: none;
      display: inline-block;
      margin-right: 8px;
    }
    i {
      color: #a1a1a1;
    }
  }
}
.em-upload-track {
  padding: 32px 24px;
  h5 {
    font-family: SFPro;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    color: #ffffff;
    margin: 0;
  }
}
.em-upload-track-list-info {
  margin: 24px 0 0 0;
  padding: 0;
  gap: 19px;

  label {
    display: block !important;
  }

  li {
    list-style: none;
    width: 100%;
    & + li {
      margin-top: 40px;
    }
    &:hover {
    }
  }
}
.em-upload-track-list {
  border-radius: 16px;
  padding: 32px;
  background: #1e1f26;
  text-align: center;
  border: 0.8px dashed #fe465a;
  

  input {
    display: none;
  }
  .em-upload-track-list-btn {
    padding: 18px 26px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 8px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    background: linear-gradient(102.9deg, #4857c0 3.03%, #fe465a 92.77%);
    border-radius: 56.0825px;
    margin-top: 20px;
  }
  h4 {
    font-family: SFPro;
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    color: #ffffff;
    margin: 10px 0 0 0;
  }
  p {
    color: #a6a6a6;
    margin: 8px 0 0 0;
    font-family: SFPro;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
  i {
    font-size: 45px;
    color: #ffffff;
    cursor: pointer;
  }
}
.em-track-title-wrap {
  margin: 40px 0 0 0;
  padding: 0;
  li + li {
    margin-top: 24px;
    list-style: none;
  }

  li {
    list-style: none;

    .form-control {
      background: rgba(255, 255, 255, 0.08) !important;
      border: 0.8px solid #a1a1a1 !important;
      border-radius: 8px;
      padding: 16px;
      margin-top: 8px;
      color: #fafafa !important;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
    label {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #a1a1a1;
    }
  }
}
.em-date-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.em-upload-btn {
  margin: 40px 0 0 auto;
  padding: 0;
  display: flex;
  gap: 16px;
  width: 118px;
  max-width: 357px;
  width: 100%;
  justify-content: end;

  li { 
    list-style: none;
  }
  a {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 12px;
    text-align: center;
    display: inline-block;
    padding: 24px 64px;
    text-decoration: none;
    color: #ffffff;
  }
}
.em-date-picker {
  position: relative;
  #em-Copyright * {
    position: absolute;
    right: 20px;
    background: transparent;
    border: none;
    color: #ffffff;
    top: 24px;
    outline: none;
    background-color: rgba(255, 255, 255, 0.08);
    color: white;
    option {
      background: rgba(255, 255, 255, 0.08);
      color: white;
    }
  }
}
.em-overlay {
  position: relative;
  max-width: 6rem;
  max-height: 6rem;
}
.em-hide {
  display: none;
}

.em-overlay:hover + .em-hide {
  display: block;
  position: absolute;
  z-index: 1;
  left: 28%;
  top: 6%;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 16px;
  padding: 14px;
  color: #ffffff;
  transition: 1s;
}
.em-upoad-btn {
  white-space: nowrap;
  font-family: "Inter", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  display: inline-block;
  padding: 24px 64px;
  text-decoration: none;
  background: transparent;
  color: #ffffff;
  border: none;
  transition: all 0.25s linear;
  &:hover {
    background: linear-gradient(102.9deg, #4857c0 3.03%, #fe465a 92.77%);
    border-radius: 80px;
    color: #ffffff;
  }
  &.active{
    background: linear-gradient(102.9deg, #4857c0 3.03%, #fe465a 92.77%);
    border-radius: 80px;
  }
}

.form-select {
  background: rgba(255, 255, 255, 0.08) !important;
  border: 0.8px solid #a1a1a1 !important;
  border-radius: 8px;
  padding: 16px;
  margin-top: 8px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  font-family: "Inter", sans-serif !important;
  option {
    color: #1e1f26;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}
.form-select:focus {
  box-shadow: none !important;
}

#em-Copyright2 * {
  position: absolute;
  right: 20px;
  background: transparent;
  border: none;
  color: #ffffff;
  top: 24px;
  outline: none;
  background-color: rgba(255, 255, 255, 0.08);
  color: white;
  option {
    background: rgba(255, 255, 255, 0.08);
    color: white;
  }
}