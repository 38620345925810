@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-HeavyItalic.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-HeavyItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-Heavy.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-Heavy.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-BlackItalic.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-BlackItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-BoldItalic.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-Black.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-Bold.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-Medium.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-Light.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-LightItalic.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-RegularItalic.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-RegularItalic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-MediumItalic.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-SemiboldItalic.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-SemiboldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-Semibold.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-Regular.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-ThinItalic.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-ThinItalic.woff') format('woff');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-Ultralight.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-Ultralight.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-Thin.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('./sfpro_fonts/SFProText-UltralightItalic.woff2') format('woff2'),
		url('./sfpro_fonts/SFProText-UltralightItalic.woff') format('woff');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}



body {
	margin: 0;
}

.container {
	width: 100%;
	max-width: 1260px;
	padding: 0 15px;
	margin: 0 auto;
}

.text-yellow{
	color: #ffde00 !important;
}
.text-aqua{
	color: #17ead9 !important;
}

/* HEADER NAVIGATION START */
.navigation-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 10px 0;
}

.nav-left {
	width: 20%;
}

.nav-left .nav-logo {
	width: 90px;
}

.nav-middle {
	width: 50%;
}

.nav-right {
	width: 20%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.nav-middle .nav-menu {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.nav-middle .nav-menu li a {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	text-align: right;
	letter-spacing: 0.200694px;
	text-decoration: none;
	color: #FFFFFF;
	display: inline-block;
}

.nav-middle .nav-menu li a:active {
	color: #EF3C5A !important;
}

.nav-middle .nav-menu li a.active {
	color: #EF3C5A !important;
	/* font-family: 'DM Sans'; */
}

.nav-right .login {
	display: inline-block;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	text-align: right;
	letter-spacing: 0.200694px;
	text-decoration: none;
	color: #FFFFFF;
	margin: 0 30px 0 0;
}

.nav-right .emergence {
	padding: 12px 18px;
}

/* HEADER NAVIGATION END */
.below-header-bg {
	background: linear-gradient(90deg, rgba(56, 45, 80, 1) 0%, rgba(58, 45, 80, 1) 57%, rgba(122, 46, 68, 1) 100%);
}

.below-header-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.below-haeder-left,
.below-haeder-right {
	width: 50%;
}

.below-haeder-right {
	display: flex;
}

.below-haeder-left .be-part {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 800;
	font-size: 60px;
	/* line-height: 80px; */
	letter-spacing: 0.2px;
	color: #FFFFFF;
	margin: 0;
}

.be-part .odd-clr {
	color: #FE465A;
}

.below-haeder-left .below-part {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 140%;
	letter-spacing: 0.2px;
	color: #A6A6A6;
}

.below-haeder-right img {
	width: 100%;
}

.emergence {
	background: linear-gradient(90deg, rgba(108, 84, 173, 1) 0%, rgba(240, 71, 98, 1) 100%);
	color: #fff;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 17px;
	text-decoration: none;
	padding: 16px 18px;
	border-radius: 35px;
	display: inline-block;
	text-transform: uppercase;
}

.digital-bg {
	background: linear-gradient(90deg, rgba(35, 21, 23, 1) 0%, rgba(18, 18, 19, 1) 49%, rgba(30, 25, 37, 1) 100%);
}

.digital-wrap {
	padding: 40px 0 30px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.digital-wrap .be-part {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 800;
	font-size: 40px;
	line-height: 90px;
	text-align: center;
	letter-spacing: 0.2px;
	text-transform: capitalize;
	color: #fff;
	margin: 0;
}

.digital-partener-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 1000px;
	margin: 20px auto;
}

.partener-inner .partener-img {
	width: 139px;
	height: auto;
}

.digital-wrap .be-part .odd-clr {
	color: #F14F30;
}

.digital-wrap .below-part {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	letter-spacing: 0.2px;
	color: #A1A1A1;
}

.digital-wrap .emergence {
	margin: 15px auto 20px;
	padding: 16px 30px;
}

.features-wrap {
	padding: 40px 0;
	text-align: center;
}

.features-wrap .be-part {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 800;
	font-size: 40px;
	line-height: 58px;
	text-align: center;
	letter-spacing: 0.2px;
	text-transform: capitalize;
	color: #fff;
	margin: 0;
}

.features-wrap .be-part .odd-clr {
	color: #DA4D65;
}

.features-bg {
	background: linear-gradient(90deg, rgba(33, 33, 41, 1) 0%, rgba(62, 43, 71, 1) 50%, rgba(53, 34, 45, 1) 100%);
}

.features-list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	margin: 30px 0;
}

.feature-inner {
	width: 19%;
	text-align: center;
	min-height: 238px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.3s all ease;
	border-radius: 16px;
	transform: translateY(0);
	transition: transform 0.25s linear;
}

.feature-inner:hover {
	background: rgba(255, 255, 255, 0.08);
	transition: 0.3s all ease;
	transform: translateY(-10px);
}

.feature-inner-link {
	display: block;
	text-decoration: none;
}

.feature-inner-link .feature-title {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	letter-spacing: 0.2px;
	color: #FFFFFF;
	margin: 10px 0;
}

.feature-img {
	height: 60px;
	width: 70px;
	object-fit: contain;
}

.feature-inner-link .feature-sub-title {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 120%;
	text-align: center;
	letter-spacing: 0.2px;
	color: #A1A1A1;
	margin: 0;
}

.features-wrap .emergence {
	background: linear-gradient(90deg, rgba(108, 84, 173, 1) 0%, rgba(240, 71, 98, 1) 100%);
	color: #fff;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 17px;
	text-decoration: none;
	padding: 16px 30px;
	border-radius: 35px;
	text-transform: uppercase;
	display: inline-block;
}

.below-features-bg {
	background: linear-gradient(90deg, rgba(29, 19, 21, 1) 0%, rgba(36, 28, 48, 1) 100%);
}

.below-features {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.below-feature-left {
	width: 50%;
	margin: 20px 0;
}

.below-feature-right {
	width: 50%;
	margin: 20px 0;
	text-align: right;
}

.below-feature-right.order {
	text-align: left;
}

.below-feature-left .feature-title {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 800;
	font-size: 35px;
	line-height: 58px;
	letter-spacing: 0.2px;
	text-transform: capitalize;
	color: #FFFFFF;
	margin: 10px 0;
}

.below-feature-left .feature-title .odd-clr {
	color: #E96B2C;
}

.below-feature-left .feature-subtitle {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 140%;
	letter-spacing: 0.2px;
	color: #A1A1A1;
}

.how-works-bg {
	background: linear-gradient(90deg, rgba(47, 34, 49, 1) 0%, rgba(55, 27, 29, 1) 100%);
}

.how-works-wrap {
	padding: 40px 0;
}

.how-work-title {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 800;
	font-size: 40px;
	line-height: 58px;
	text-align: center;
	letter-spacing: 0.2px;
	text-transform: capitalize;
	color: #FFFFFF;
	margin: 0;
	margin-bottom: 40px;
}

.how-work-title .odd-clr {
	color: #DD535D;
}

.how-work-list {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	text-align: center;
	background-image: url("images/frontend/emergence-works.png");
	background-repeat: no-repeat;
	background-position: top center;
	background-size: 900px;
}

.how-work--inner .feature-title {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	letter-spacing: 0.2px;
	color: #FFFFFF;
	margin: 30px 0 10px;
}

.how-work--inner .feature-sub-title {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	/* line-height: 120%; */
	text-align: center;
	letter-spacing: 0.2px;
	color: #A1A1A1;
	margin: 0;
}

.how-work--inner {
	width: 24%;
	min-height: 238px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* ARTIST COMMUNITY START */
.artist-community-bg {
	background: linear-gradient(90deg, rgba(33, 32, 42, 1) 0%, rgba(60, 43, 71, 1) 50%, rgba(46, 33, 43, 1) 100%);
}

.artisti-community-wrap {
	padding: 30px 0;
	text-align: center;
}

.artisti-community-wrap .emergence {
	margin: 30px 0 0;
}

.community-title {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 800;
	font-size: 40px;
	line-height: 58px;
	text-align: center;
	letter-spacing: 0.2px;
	text-transform: capitalize;
	color: #FFFFFF;
	margin-bottom: 0;
}

.community-title .odd-clr {
	color: #E25C49;
}

.community-sub-title {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	/* line-height: 140%; */
	text-align: center;
	letter-spacing: 0.2px;
	color: #A1A1A1;
}

.community-inner {
	text-align: center;
	max-width: 260px;
	transform: translateY(0);
	transition: transform 0.25s linear;
}

.community-inner:hover {
	transform: translateY(-10px);
}

.community-inner .artist-img {
	width: 100%;
	max-width: 260px;
}

.slick-initialized .slick-slide {
	text-align: center;
	padding: 0px 0 !important;
}

.slick-slide.slick-current.slick-active {
	/* background: rgba(196, 196, 196, 0.08); */
	border-radius: 16px;
	padding: 10px 0;
}

.slick-track {
	margin-left: unset !important;
	margin-right: unset !important;
}

.artist-name {
	font-family: 'SF Pro Text';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 140%;
	text-align: center;
	letter-spacing: 0.2px;
	color: #A1A1A1;
	margin: 0;
	padding: 10px 0;
}

/* ARTIST COMMUNITY END */
/* FOOTER  START */
.footer-bg {
	background: #212121;
}

.footer-wrap {
	padding: 30px 0;
}

.footer-wrap {
	padding: 30px 0;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.footer-inner {
	/* width: 19%; */
}

.footer-inner .title-footer {
	/* font-family: 'SF Pro Display'; */
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.2px;
	margin: 10px 0;
	color: #FFFFFF;
}

.footer-inner .footer-list {
	margin: 0;
	padding: .0;
	list-style: none;
}

.footer-list li a {
	/* font-family: 'SF Pro Display'; */
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.2px;
	color: #A1A1A1;
	display: inline-block;
	text-decoration: none;
	padding: 10px 0;
	transition: color 0.25s linear;
}

.footer-list li a:hover {
	color: #EF3C5A;
}

.footer-inner .footer-subscribe {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.footer-inner .footer-subscribe label {
	/* font-family: 'SF Pro Display'; */
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	display: flex;
	align-items: center;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #A1A1A1;
	padding: 10px 0;
}

.footer-inner .email {
	background: rgba(218, 223, 229, 0.1);
	border-radius: 8px;
	border-color: transparent;
	color: #fff;
	padding: 19px 0px;
	width: calc(100% - 20px);
}

.footer-inner .email:focus {
	outline: none;
	box-shadow: none;
	border: none;
}

#Email:focus-visible {
	border: 0;
	box-shadow: none;
	outline: 0;
}

.agreement-wrap {
	display: flex;
	align-items: center;
}

.agreement-wrap label {
	margin: 0 0 0 10px;
}

.agreement-wrap .checkbox {
	height: 18px;
	width: 18px;
	margin: 0;
}

/* FOOTER  END */
.slick-next::before,
.slick-prev::before {
	display: none !important;
}

.slick-next {
	right: 25px !important;
}

.slick-next {
	right: -25px !important;
	background-image: url('./images/frontend/arrow.png') !important;
	background-repeat: no-repeat !important;
	background-position: center center !important;
	transform: rotate(179deg) !important;
}

@media screen and (max-width: 767px) {
	.slick-prev {
		left: 5px !important;
	}

	.slick-next {
		right: 5px !important;
	}

	.slick-prev,
	.slick-next {
		transform: translateY(-50%);
	}
}

.slick-next.slick-arrow,
.slick-prev.slick-arrow {
	width: 40px;
	height: 40px;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 100%;
}

.slick-prev.slick-arrow {
	background-image: url('./images/frontend/arrow.png') !important;
	background-repeat: no-repeat;
	background-position: center center;
}

.slick-prev:hover {
	background-image: url('./images/frontend/arrow.png') !important;
	background-repeat: no-repeat !important;
	background-position: center center !important;
}

.mobile-menu .nav-item.active {
	background: linear-gradient(90deg, rgba(108, 84, 173, 1) 0%, rgba(240, 71, 98, 1) 100%);
}

/* media Query Start*/
/* -------For large Screen---------- */
@media(max-width:1200px) {
	.container {
		width: 100%;
		max-width: 1260px;
		/* padding: 0px; */
		margin: 0 auto;
	}

	.below-haeder-left .below-part {
		font-size: 16px;
		padding: 15px 0;
	}

	.digital-wrap .be-part {
		font-size: 18px;
	}

	.digital-wrap .below-part {
		font-size: 16px;
		margin-top: 15px;
		line-height: unset;
	}

	.partener-inner .partener-img {
		width: 100px;
		height: auto;
	}

	.feature-inner-link .feature-title {
		font-size: 16px;
		line-height: 140%;
	}

	.feature-sub-title br {
		display: none;
	}

	.below-feature-left .feature-title {
		font-size: 32px;
		line-height: 25px;
	}

	.below-feature-right img {
		width: 70%;
	}

	.digital-partener-wrap {
		justify-content: space-around;
	}

	.below-header-wrap {
		padding: 0px 15px;
	}

	.below-haeder-left .be-part {
		font-size: 30px;
		/* line-height: 30px; */
	}

	.digital-wrap {
		padding: 40px 15px 30px 15px;
	}

	.features-wrap {
		padding: 40px 15px;
	}

	.below-features {
		padding: 0px 15px;
	}

	.footer-inner .email {
		padding: 19px 0px;
	}

	.footer-wrap {
		padding: 30px 15px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

#click {
	display: none;
}

.menu-btn i {
	display: none;
}

@media(max-width:991px) {
	.nav-left .nav-logo {
		width: 65px;
		height: auto;
		padding-left: 35px;
	}

	.nav-left {
		width: 30%;
	}

	.nav-right {
		width: 60%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: 2rem;
	}

	@media (max-width: 767px) {
		.nav-right {
			margin-top: 0rem;
		}
	}

	.nav-middle {
		width: 0%;
	}

	.menu-btn i {
		display: block;
		font-size: 30px;
		position: absolute;
		left: 10px;
		color: #fff;
		top: 3%;
	}

	#click:checked~.menu-btn i::before {
		content: "\f00d";
	}

	.nav-menu {
		position: absolute;
		display: block !important;
		/* left: -100%; */
		left: 0%;
		background: #000;
		width: 50%;
		font-size: 30px;
		top: 8%;
		height: 100vh;
		transition: all .5s ease;
	}

	.desktop-menu {
		display: none !important;
	}

	.nav-menu li {
		padding: 5px 10px;
		border-bottom: 1px solid #fff;
	}

	#click:checked~ul {
		left: 0%;
	}

	.navigation-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 10px;
	}

	.footer-inner:first-child {
		width: 100%;
		text-align: center;
		margin-bottom: 20px;
	}
}

/* -------For tablet Screen---------- */
@media(max-width:800px) {
	.container {
		width: 100%;
		max-width: 1260px;
		/* padding: 0px; */
		margin: 0 auto;
	}

	.feature-sub-title br {
		display: block;
	}

	.below-haeder-right img {
		/* width: 80%; */
		display: block;
		margin: 0 auto;
	}

	.below-header-wrap {
		display: flex;
		align-items: end;
		justify-content: space-between;
		flex-wrap: wrap;
		flex-direction: row;
		padding: 0px 20px;
	}

	.below-haeder-left {
		padding-top: 15px;
		padding-bottom: 15px;
		align-self: center;
	}

	.below-haeder-left,
	.below-haeder-right {
		width: 50%;
	}

	.below-haeder-left .be-part {
		font-size: 24px;
		/* line-height: 40px; */
	}

	.below-part br {
		display: none;
	}

	.emergence {
		font-size: 14px;
		line-height: 14px;
		padding: 14px 12px;
	}

	.digital-wrap .emergence {
		margin: 15px auto 20px;
		padding: 14px 20px;
	}

	/* 2nd section */

	.digital-wrap .be-part {
		font-size: 24px;
		line-height: 40px;
	}

	.digital-partener-wrap {
		display: flex;
		align-items: center;
		justify-content: start;
		width: 100%;
		max-width: 1000px;
		margin: 20px auto;
		flex-wrap: wrap;
	}

	.partener-inner {
		width: 30%;
		padding-bottom: 30px;
		text-align: center;
	}

	.partener-inner .partener-img {
		width: 100px;
		height: auto;
	}

	.digital-wrap {
		padding: 40px 20px 30px 20px;
	}

	/* 3rd section */
	.features-wrap .be-part {
		font-size: 26px;
		line-height: 40px;
	}

	.feature-inner {
		min-height: 200px;
		width: 50%;
	}

	.features-wrap .emergence {
		font-size: 16px;
		padding: 16px 16px;
	}

	.features-list {
		justify-content: center;
	}

	.features-wrap {
		padding: 40px 20px;
	}

	/* 4th section */
	.below-features {
		padding: 20px;
	}

	.below-feature-left {
		width: 100%;
		margin: 20px 0;
		text-align: center;
	}

	.below-feature-left .feature-title {
		font-size: 18px;
		line-height: 20px;
	}

	.below-feature-left .feature-subtitle {
		font-size: 16px;
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.below-feature-right {
		width: 100%;
		margin: 20px 0;
		text-align: center;
	}

	.below-feature-right img {
		width: 50%;
		text-align: center;
		display: block;
		margin: 0 auto;
	}

	.order {
		order: 2;
	}

	.order-1 {
		order: 1;
	}

	.order-2 {
		order: 3;
	}

	.order-3 {
		order: 4;
	}

	/* 5th section */
	.how-work-title {
		font-size: 26px;
	}

	.how-work-list {
		background-image: none;
	}

	ul.how-work-list {
		display: flex;
		flex-wrap: wrap;
		padding: 0 15px;
	}

	.community-sub-title {
		margin-top: 15px;
	}

	.how-work--inner {
		width: 50%;
	}

	/* 6th section */
	.community-title {
		font-size: 28px;
		line-height: 35px;
	}

	.community-sub-title br {
		display: none;
	}

	/* 7th section */
	.footer-inner:first-child {
		width: 100%;
		text-align: center;
		margin-bottom: 20px;
	}

	.footer-inner {
		width: 50%;
	}

	.footer-inner .email {
		padding: 19px 0px;
	}

	.footer-wrap {
		padding: 20px 20px 60px;
	}
}

/* -------For Mobile Screen---------- */
@media(max-width:480px) {
	.nav-menu {
		width: 100%;
	}

	.container {
		width: 100%;
		max-width: 1260px;
		padding: 0 15px;
		margin: 0 auto;
	}

	.below-header-wrap {
		display: flex;
		align-items: start;
		justify-content: space-between;
		flex-wrap: wrap;
		flex-direction: column;
		padding: 0px 10px;
	}

	.below-haeder-left,
	.below-haeder-right {
		width: 100%;
		text-align: center;
	}

	.below-haeder-left .below-part {
		font-size: 14px;
		line-height: 1.6;
		padding: 10px 0;
	}

	.below-haeder-left .be-part {
		font-size: 18px;
		line-height: 1.6;
	}

	.below-part br {
		display: none;
	}

	.emergence {
		font-size: 12px;
		line-height: 5px;
		padding: 15px 10px;
	}

	.digital-wrap .emergence {
		margin: 15px auto 20px;
		padding: 14px 20px;
	}

	/* 2nd section */

	.digital-wrap {
		padding: 20px 10PX 30px 10PX;
	}

	.digital-wrap .be-part {
		font-size: 18px;
		line-height: 1.6;
		padding-bottom: 10px;
	}

	.digital-partener-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 1000px;
		margin: 20px auto;
		flex-wrap: wrap;
	}

	.partener-inner {
		width: 50%;
		padding: 15px;
		text-align: center;
	}

	.partener-inner .partener-img {
		width: 100px;
		height: auto;
	}

	.digital-wrap .below-part {
		font-size: 14px;
		line-height: 1.6;
	}

	/* 3rd section */
	.features-wrap .be-part {
		font-size: 18px;
		line-height: 1.6;
	}

	.feature-inner {
		width: 100%;
	}

	.features-wrap .emergence {
		font-size: 12px;
		padding: 8px 10px;
	}

	.feature-inner {
		min-height: 185px;
	}

	.feature-inner-link .feature-sub-title {
		font-size: 14px;
	}

	/* 4th section */
	.below-features {
		padding: 10px;
	}

	.below-feature-left {
		width: 100%;
		margin: 20px 0;
	}

	.below-feature-left .feature-title {
		font-size: 18px;
		line-height: 1.6;
	}

	.below-feature-left .feature-subtitle {
		font-size: 14px;
		margin: 24px 0;
	}

	.below-feature-right {
		width: 100%;
		margin: 20px 0;
		text-align: center;
	}

	.below-feature-right img {
		width: 60%;
		text-align: center;
		margin: 0 auto;
		display: block;
	}

	.order {
		order: 2;
	}

	.order-1 {
		order: 1;
	}

	.order-2 {
		order: 3;
	}

	.order-3 {
		order: 4;
	}

	/* 5th section */
	.how-work-title {
		font-size: 18px;
	}

	.how-work-list {
		background-image: none;
	}

	ul.how-work-list {
		display: flex;
		flex-wrap: wrap;
	}

	.how-work--inner {
		width: 100%;
	}

	/* 6th section */
	.community-title {
		font-size: 18px;
		line-height: 1.6;
	}

	.community-sub-title br {
		display: none;
	}

	.how-work--inner .feature-title {
		margin: 10px 0 10px;
	}

	.how-work--inner {
		min-height: 170px;
		margin-bottom: 50px;
	}

	.how-work--inner .feature-sub-title {
		font-size: 14px;
	}

	.how-work--inner .feature-title {
		font-size: 16px;
	}

	.how-works-wrap {
		padding: 15px 0;
	}

	/* 7th section */
	.footer-inner {
		width: 100%;
	}

	.footer-menu {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.footer-inner {
		width: 50%;
		margin-bottom: 10px;
		padding-bottom: 20px;
	}

	.footer-inner:first-child {
		width: 100%;
		text-align: center;
	}

	.footer-inner .email {
		padding: 10px 0px;
	}

	.footer-wrap {
		padding: 30px 10px;
	}

	.community-sub-title {
		font-size: 14px;
		padding: 10px 10px;
	}
}

/* media Query End*/