.StripeCheckout {
    font-family: "SfPro" !important;
    white-space: nowrap;
    padding: 1rem;
    background: #6e6e6e;
    border-radius: 80px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    color: #ffffff;
    width: -webkit-fill-available;
    text-decoration: none;
}

.StripeCheckout:hover {
    background: linear-gradient(102.9deg, #4857c0 3.03%, #fe465a 92.77%);
    color: #ffffff;
}

/* .StripeCheckout.active {
    background: linear-gradient(102.9deg, #4857c0 3.03%, #fe465a 92.77%);
    color: #ffffff;
  } */

iframe.stripe_checkout_app .overlayView .bodyView {
    background: #000 !important;
}

iframe.stripe_checkout_app .bodyView input {
    border: 0.8px solid #a1a1a1;
    border-radius: 8px;
    padding: 15px 40px 16px 16px;
    background: transparent;
    color: #ffffff;
}