/* Google Font Link */

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 78px;
  background: rgba(0, 0, 0, 0.4);
  padding: 6px 14px;
  z-index: 99;
  height: 100%;
  transition: all 0.5s ease;
}

/* @media screen and (max-width: 480px) {
    .sidebar{
      overflow-y: auto;
    }
} */

.sidebar ul {
  padding: 0;
}

.sidebar.open {
  width: 263px;
}


.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
  margin-left: 1.3rem;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details #btn {
  text-align: right;
}

.sidebar i {
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.sidebar .nav-list {
  /* margin-top: 20px; */
  height: 100%;
}

.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}

@media screen and (max-width: 480px) {
  .sidebar li {
    margin: 0;
  }
}

.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}

.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}

.sidebar.open li .tooltip {
  display: none;
}

.sidebar input {
  font-size: 15px;
  color: #FFF;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #1d1b31;
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
}

.sidebar li a:hover {
  background: #FFF;
}

.sidebar li a.active {
  background: #FFF;
}

.sidebar li a .links_name {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.links_name {
  color: #fff;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i, .sidebar li a.active i {
  transition: all 0.5s ease;
  color: #11101D;
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}

.sidebar .logo-details .bx-menu {
  opacity: 0;
}

.sidebar li.profile {
  position: fixed;
  /* height: 135px; */
  width: 78px;
  left: 0;
  bottom: -8px;
  /* padding: 10px 14px; */
  background: transparent;
  transition: all 0.5s ease;
  overflow: hidden;
}

.logout-sec {
  display: flex;
  color: #fff;
  margin-left: 10px;
}

.logout-sec h6 {
  color: #fff;
}

.sidebar.open li.profile {
  width: 250px;
}

.sidebar li .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

.sidebar li .profile-details p {
  margin: 0;
  
}

@media screen and (max-width:992px){
  .sidebar li .profile-details p {
    text-align: center;
  }
}

.sidebar li img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 6px;
  /* margin-right: 10px; */
}

.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}

.sidebar li.profile .job {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #1d1b31;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}

.home-section {
  position: relative;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}

.sidebar.open~.home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px
}

.burger-menu {
  display: none;
}

@media (max-width: 992px) {
  .burger-menu {
    display: block;
    margin-left: 1rem;
    margin-top: 5px;
    color: #fff;
  }

  .home-section {
    width: 100%;
    left: 0;
    padding: 0 15px;
  }
  .em-top-release-music-wraper li .em-top-release-music-info{
    padding: 8px 10px;
  }

  /* .sidebar{
    margin-left: -78px;
  } */
}

@media (max-width: 480px) {
  .burger-menu {
    margin-top: 0px;
  }

  .home-section {
    width: 100%;
    left: 0;
    padding: 0 15px;
  }

  .sidebar li .tooltip {
    display: none;
  }
}