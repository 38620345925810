:root {
  /* --bs-pagination-active-color: ""; */
  --pagination-active-bg: #201a2c;
  --pagination-active-border-color: #35235a;
}

html,body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  background-color: var(--pagination-active-bg) !important;
  border-color: var(--pagination-active-border-color) !important;
}

.album-card {
  padding: 0rem !important;
  transition: all 0.25s linear;
}

@media screen and (max-width:480px) {
  .album-card {
    padding: 1rem !important;
  }
}

.album-card.active,
.album-card:hover {
  background: rgba(196, 196, 196, 0.08);
  border-radius: 16px;
  padding: 1rem !important;
}

.slick-track {
  margin-left: 0;
}

.news-card {
  background: transparent;
  transition: all 0.25s linear;
}

.news-card:hover {
  background-color: #262626;
}

.news-card:hover img {
  transform: scale(1.2) rotate(-15deg);
}

.news-card .thumbnail {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.news-card .blur {
  backdrop-filter: blur(10px);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
}

.news-card img {
  z-index: 1;
  transition: all 0.25s linear;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}



.empty {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.empty i {
  font-size: 80px;
  color: rgba(255, 255, 255, 0.2) !important;
}

.empty p {
  color: rgba(255, 255, 255, 0.2) !important;
  font-size: 18px;
}

.empty p:first-of-type {
  color: #ffffff80 !important;
  font-weight: 700;
}

.rang-table tbody tr {
  transition: all 0.25s linear;
}

.rang-table tbody tr:hover {
  background-color: #d6414638;
}

.service-card,
.service-card a {
  transition: all 0.25s linear;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-card:hover a {
  background: linear-gradient(102.9deg, #4857c0 3.03%, #fe465a 92.77%);
}