.em-dashboard {

  .em-db-sidebar {
    padding: 19px 16px;
    background: #1e1f26;


    strong {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: "sfPro";
      span {
        height: 26px;
        width: 121px;
        left: 98px;
        top: 46px;
        color: #ffffff;
        font-family: "sfPro";
        letter-spacing: 0.2px;
      }
    }
    .em-db-footer {
      margin-top: 150px;
      a {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        color: #e7e7e7;
        text-decoration: none;
        margin-top: 34px;
        font-family: "sfPro";
      }
      i {
        margin-right: 14px;
        color: #e7e7e7;
      }
    }
    .em-db-footer-wraper {
      display: flex;
      align-items: center;
      gap: 16px;

      h6 {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        color: #ffff;
        margin: 0;
        font-family: "sfPro";
      }
      em {
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #e7e7e7;
        margin: 4px 0 0 0x;
        font-family: "sfPro";
      }
    }
  }
}
.em-db-sidebar-wraper {
  padding: 0;
  margin: 40px 0 0 0;
  li {
    list-style: none;

    & ~ li {
      margin-top: 8px;
    }
    a {
      text-decoration: none;
      display: block;
      color: #e7e7e7;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      padding: 16px;
      font-family: "sfPro";

      &:hover,
      :active {
        background: rgba(255, 255, 255, 0.08);
        border-radius: 16px;
        color: #ffffff;
      }
    }
  }
}
.em-db-content {
  padding: 40px 24px 30px 24px;
  width: 100%;
  position: relative;
  z-index: 2;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
.slick-dots {
    li{
      width: 10px !important;
    }
  bottom: 14px !important;
}

.slick-dots li.slick-active button:before{
  color: #ffff !important;
}
.slick-dots li button:before{
  font-size: 12px !important;
}

.em-db-content-wraper {
  margin-top: 24px;
  .em-db-marketing {
    max-width: 691px;
  }
  .em-db-marketing-info {
    display: flex;
    align-items: center;
    background: linear-gradient(102.9deg, #4857c0 3.03%, #fe465a 92.77%);
    border-radius: 16px;
    padding: 32px;
    // max-width: 691px;
    width: 100%;
    @media screen and (min-width: 1200px) {
      padding: 32px 15px !important;
    }
    @media screen and (max-width: 767px) {
      padding: 15px !important;
    }
    @media screen and (max-width: 480px) {
      padding: 15px !important;
    }
    .db-get-marketing {
      h3 {
        font-family: "sfPro";
        font-size: 18px;
        font-weight: 600;
        line-height: 36px;
        margin: 0;
        color: #ffffff;
        @media screen and (min-width: 1200px) {
          font-size: 22px;
        }
      }
      p {
        font-family: "sfPro";
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin: 10px 0 0 0;
        color: #ffffff;
        @media screen and (min-width: 1200px) {
          font-size: 11px;
        }
      }
      .db-marketing-btn {
        font-family: "sfPro";
        font-size: 16px;
        display: inline-block;
        font-weight: 500;
        line-height: 12px;
        padding: 15px 36px;
        border: 1px solid #ffffff;
        border-radius: 80px;
        text-decoration: none;
        color: #ffffff;
        margin-top: 33px;
      }
    }
  }
}
.em-db-music {
  margin: 0;
  padding: 0;
  margin-left: 16px;
  li {
    padding: 12px 30px;
    list-style: none;
    background: #00000066;
    border-radius: 16px;
    // max-width: 203px;
    // width: 100%;
    @media screen and (max-width:480px) {
      padding: 10px;
    }
    & + li {
      margin-top: 16px;
    }
    i {
      display: block;
      color: #fafafa;
    }
    span {
      font-family: "sfPro";
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      color: #fafafa;
      opacity: 0.6;
      display: block;
      margin-top: 14px;
      white-space:  nowrap;
    }
    em {
      font-family: "sfPro";
      font-size: 32px;
      font-weight: 500;
      line-height: 38px;
      color: #ffffff;
      font-style: normal;
      margin-top: 4px;
      display: inline-block;
    }
  }
}
.em-my-music {
  margin-top: 32px;



  h4 {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    color: #ffffff;
    font-family: "sfPro";
  }

  @media screen and (max-width: 480px) {
    .album-card-wrapper{
      width: 50% !important;
    }
    h4 {
      font-size: 20px;
    }
  }
  .em-my-music-slider {
    margin-top: 24px 0 0 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
    li {
      list-style: none;
    }
  }
  .em-my-music-slider-wraper {
    img{
      display: inline;
      max-width: 150px;
    }
    .em-my-music-slider-info {
      margin-top: 12px;
      h6 {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        color: #ffffff;
        margin: 0;
        font-family: "sfPro";
      }
      span {
        color: #a1a1a1;
        display: block;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        margin-top: 4px;
        font-family: "sfPro";
      }
    }
  }
  
}
.em-top-release-music-wraper {
  margin: 24px 0 0 0;
  padding: 0;
  li {
    list-style: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    cursor: pointer;
    
    .active, &:hover,
    :active {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.04) 19.58%,
        rgba(255, 255, 255, 0.04) 80.52%,
        rgba(255, 255, 255, 0) 100%
      );
      h6 {
        color: #e7e7e7;
        font-family: "sfPro";
      }
    }
    .em-top-release-music-info {
      padding: 8px 64px 8px 32px;
      display: flex;
      align-items: center;
      @media screen and (min-width:992px) {
        min-height: 100px;
      }
      @media screen and (max-width:480px) {
        padding: 10px;
        overflow-wrap: anywhere;
      }
    }
  }
  &:nth-child(2) {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.04) 19.58%,
      rgba(255, 255, 255, 0.04) 80.52%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .em-top-release-music-profie {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-left: 16px;

  }
}

@media(max-width:768px){
  .slick-slide {
    // margin: 0 10px;
  }
}


.em-top-release-music-info {
  h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin: 0 36px 0 0;
    color: #e7e7e7;
    font-family: "sfPro";
  }
  h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #767676;
    font-family: "sfPro";
  }
  span {
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    color: #767676;
    font-family: "sfPro";
  }
  .em-top-release-music-time {
    color: #767676;
    display: flex;
    @media screen and (max-width:480px) {
      min-width: 100px;
      padding: 0 7px;
      justify-content: flex-end;
    }
    span{
      padding-left: 8px;
    }
  }
  }
  .em-db-content-title {
    h2 {
      color: #ffffff;
      margin: 0;
      font-family: SF Pro Text;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0em;
      font-family: "sfPro";
      text-align: left;
      @media screen and (max-width: 480px) {
        font-size: 20px;
      }
    }
  }
  .em-top-release-music {
    margin-top: 32px;
    h5{
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      font-family: "sfPro";
      margin: 0;
      @media screen and (max-width: 480px) {
        font-size: 20px;
      }
    }
  }
  .em-top-release-music-info{
    img{
      margin: 0 0 0 36px;
      @media screen and (max-width: 480px) {
          margin-left: 15px;
      }
      
    }
    img.em-thumbnail{
      max-width: 65px;
      max-height: 65px;
    }
    span{
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      color: #e7e7e7;
      font-family: "sfPro";
      display: block;
    }
  }