@media (max-width: 1036px) {
  .em-sign-in-btn {
    width: 50%;
  }
}

@media (min-width: 767px) and (max-width: 1040px) {
  .em-sign-in-btn {
    padding: 0.7rem 8rem !important;
    font-size: 14px;
  }
  h3 {
    font-size: 18px !important;
  }
  .em-signup {
    em {
      font-size: 12px !important;
    }
  }
  label{
    font-size: 12px !important;
  }
  .em-account-login {
    font-size: 14px !important;
  }
  ::placeholder {
    font-size: 15px !important;
  }
  .em-input {
    i {
      font-size: 15px !important;
      top: 20px;
    }
  }
}
@media (max-width: 880px) {
  .em-sign-in-btn {
    padding: 0.7rem 7rem !important;
    font-size: 11px;
  }
}
@media (max-width: 767px) {
  .em-row {
    .col-8 {
      display: none;
    }
    .col-4 {
      width: 100%;
    }
    .em-sign-in-btn {
      width: 100%;
    }
    .em-sign-in-btn {
      padding: 1rem 10rem !important;
    }
  }
}
@media (max-width: 392px) {
  .em-sign-in-btn {
    max-width: 50%;
  }
}
@media (max-width: 991px) {
  .em-form .form-control {
    padding: 12px 40px 12px 13px !important;
  }
}

@media (max-width: 991px){
  .em-upload-track-info{
    flex-wrap: wrap;
    & li{
      width: 100%;
    }
  }
}
@media (min-width: 992px) and (max-width: 1399px){
  .navbar-collapse {
    
  }
}

@media (max-width: 1199px){
  .em-db-marketing-info{
    width: 100% !important;
    max-width: 100% !important;
  }
  .em-db-marketing{
    flex-direction: column;
  }
  .em-db-music{
    display: flex;
    gap: 16px;
    margin: 16px 0 0 0 !important;
    justify-content: center;
  }
  .em-db-music li + li{
    margin: 0 !important;
  }
  .em-db-music li{
    &:first-child{
      flex: unset;
    }
    width: 50% !important;
  }
}

@media(max-width:767px) {
  .em-my-music-slider{
    justify-content: center;
  }
  .em-db-marketing-info{
    // flex-direction: column;
  }
   .db-marketing-btn{
    display: inline-flex !important;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .db-marketing-img{
    order: -1;
  }
  .db-get-marketing{
    text-align: center;
  }

  .db-get-marketing{
    h3{
      font-size: 12px !important;
      line-height: 1.6 !important
    }
    p{
      font-size: 10px !important;
      line-height: unset !important;
    }

    a{
      font-size: 12px !important;
      display: inline-block !important;
      font-weight: 500 !important;
      line-height: 12px !important;
      padding: 10px 20px !important;
      border: 1px solid #ffffff !important;
      border-radius: 80px !important;
      text-decoration: none !important;
      color: #ffffff !important;
      margin: 20px !important;
    }
  }

  .em-db-content-wraper .em-db-marketing-info{
    padding: 2px !important;
  }

  .db-marketing-img img{
    max-width: 7rem;
  }
}



@media(max-width:575px) {
  .em-db-music{
    flex-direction: row ;
    li{
      &:first-child{
        flex: unset;
      }
      width: 50% !important;
      text-align: center;
    }
  }
  .em-my-music-slider {
    align-items: flex-start;
    justify-content: flex-start;
}
}